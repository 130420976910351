import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { Box, CircularProgress} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import ApartmentCard from "../../components/ApartmentCard"

const Accounts = () => {

    const [accountsList, setAccountsList] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const getAllAccounts = async() => {
        try{
            setIsLoading(true)
            console.log('getting accounts')
            const resp = await axios.get(`${process.env.REACT_APP_BACKEND_API_HOST}/api/accounts/`)
            setAccountsList(resp.data)
            setIsLoading(false)

            // console.log(resp.data)

            return resp.data;
        } catch (error){
            setAccountsList([])
            console.error(error)
        }
    }

    useEffect(() => {
        // console.log('querying!')
        getAllAccounts()
    }, [])

    return (
        isLoading ? 
            <div style={{width: "100%", minHeight: "10em", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <CircularProgress />
            </div>
            :
            <div style={{display: "flex", justifyContent: "center"}} data-testid='accounts-div'>
                <Box sx={{width: "75%", paddingTop: "1%"}}>
                    <Grid2 container spacing={5}>
                        {
                            accountsList.map((acct, i) => (
                                <Grid2 key={acct.id} xs={3}>
                                    <ApartmentCard account={acct} data-testid='account-card'/>
                                </Grid2>
                            ))
                        }
                    </Grid2>
                </Box>
            </div>
    )
}


export default Accounts