import React from 'react';
import { Typography, Box, Button } from '@mui/material';

const NotFound = () => (
    <Box 
        sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            height: '100vh', 
            textAlign: 'center', 
            gap: '2rem' 
        }}
    >
        <Typography variant="h1" component="div">
            404 - Page Not Found
        </Typography>
        <Button variant="outlined" href='/accounts'>Back to Home</Button>
    </Box>
)

export default NotFound