import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Typography, Card, CardContent, Chip, CircularProgress } from '@mui/material';

const ClaimDetail = () => {
    const { id: claimId} = useParams();
    const [claim, setClaim] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getClaimDetails = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_HOST}/api/claims/${claimId}`);
            setClaim(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Failed to get claim details:', error);
        }
    };

    useEffect(() => {
        getClaimDetails();
    }, [claimId]);

    if (!claim) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>;
    }

    return (
        isLoading ? 
        <div style={{width: "100%", minHeight: "10em", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <CircularProgress />
        </div>
        :
        <>
        <div style={{backgroundColor: ''}}>
        
            <div style={{marginLeft: '10em', marginRight: '10em', marginTop: '3em'}}>
                <Card variant="outlined" style={{minHeight:'50vh', boxShadow: '5px 5px 20px #E9E9E9', borderRadius: '1em'}}>
                    <CardContent>
                        <Typography data-testid='claim-detail-acctName' variant="h4" style={{padding: '0.5em'}}>{claim.accountName}</Typography>
                        <Typography data-testid='claim-detail-lossType' variant="body1" style={{padding: '1em'}}>Claim Type: {claim.lossType}</Typography>
                        <Typography variant="div" style={{padding: '1em'}}>Claim Status: <Chip data-testid='claim-detail-status' label={claim.claimStage} sx={{borderRadius: '0.5em', marginTop: '1em', marginBottom: '1em'}} color='success' /> </Typography>
                        <Typography data-testid='claim-detail-dateOfLoss' variant="body1" style={{padding: '1em'}}>Date of Loss: {claim.dateOfLoss}</Typography>
                        <Typography data-testid='claim-detail-description' variant="body1" style={{padding: '1em'}}>Description: {claim.description} </Typography>
                    </CardContent>
                </Card>
            </div>
        </div>
        </>
    );
};

export default ClaimDetail;