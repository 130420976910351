import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Button, Typography, Card, CardContent, CircularProgress, TableCell, TableBody, TableContainer, TableRow, Table, TableHead, Paper } from '@mui/material';
import axios from 'axios';
import complex_placeholder from '../../images/complex_placeholder.png';

const AccountDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    
    const [acctDetails, setAcctDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const handleSubmitClaims = async() => {
        try {
            navigate(`${location.pathname}/submit-claim`, {state: {acctId: acctDetails.id, acctName: acctDetails.name}})
        } catch (error) {
            console.error('Navigation failed:', error);
        }
    }

    const getAccountDetails = async() => {
        try{
            setIsLoading(true)
            const resp = await axios.get(`${process.env.REACT_APP_BACKEND_API_HOST}/api/accounts/${id}`)
            // console.log(resp)
            if(resp.status !== 200) navigate(`${location.pathname}/404`)
            setAcctDetails(resp.data)
            setIsLoading(false)
        } catch (error){
            console.error('Failed to get account details:', error)
        }
    }

    useEffect(() => {
        getAccountDetails()
    }, [id])

    return (
        isLoading ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
            :
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', height: '100vh', marginTop: '3em', marginLeft: '11em', marginRight: '10em'}}>
            <Button variant='contained' onClick={handleSubmitClaims} data-testid='acct-submit-claim'> Submit Claims </Button>
            <Card variant="outlined" style={{minHeight:'50vh', boxShadow: '5px 5px 20px #E9E9E9', borderRadius: '1em', marginTop: '1em'}}>
                <CardContent style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: '30%', padding: '1em' }}>
                        <img src={acctDetails.propertyImage == null ? complex_placeholder : acctDetails.propertyImage} alt={acctDetails.name} style={{ width: '100%', height: '100%', borderRadius: '1em' }} />
                    </div>
                    <div style={{ flex: '70%', padding: '1em', marginLeft: '3em' }}>
                        <Typography variant="h4" style={{marginBottom: '1em'}} data-testid='acct-name'>{acctDetails.name}</Typography>
                        <Typography variant="body1" data-testid='acct-llc'>LLC Name: {acctDetails.llcName}</Typography>
                        <Typography variant="body1" data-testid='acct-addr'>Address: {acctDetails.address} </Typography>
                        <TableContainer component={Paper} style={{marginTop: '3em'}}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ backgroundColor: '#E9E9E9'}}>
                                    <TableCell>Insurance Type</TableCell>
                                    <TableCell>Effective Date</TableCell>
                                    <TableCell>Expiration Date</TableCell>
                                    <TableCell>Premium</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {acctDetails.glEffectiveDate && acctDetails.glExpirationDate && acctDetails.glPremium && (
                                    <TableRow>
                                        <TableCell>GL</TableCell>
                                        <TableCell>{acctDetails.glEffectiveDate}</TableCell>
                                        <TableCell>{acctDetails.glExpirationDate}</TableCell>
                                        <TableCell>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(acctDetails.glPremium)}</TableCell>
                                    </TableRow>
                                )}
                                {acctDetails.umbrellaEffectiveDate && acctDetails.umbrellaExpirationDate && acctDetails.umbrellaPremium && (
                                    <TableRow>
                                        <TableCell>Umbrella</TableCell>
                                        <TableCell>{acctDetails.umbrellaEffectiveDate}</TableCell>
                                        <TableCell>{acctDetails.umbrellaExpirationDate}</TableCell>
                                        <TableCell>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(acctDetails.umbrellaPremium)}</TableCell>
                                    </TableRow>
                                )}
                                {acctDetails.propertyEffectiveDate && acctDetails.propertyExpirationDate && acctDetails.propertyPremium && (
                                    <TableRow>
                                        <TableCell>Property</TableCell>
                                        <TableCell>{acctDetails.propertyEffectiveDate}</TableCell>
                                        <TableCell>{acctDetails.propertyExpirationDate}</TableCell>
                                        <TableCell>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(acctDetails.propertyPremium)}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}


export default AccountDetails