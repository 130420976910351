import React from 'react';
import { useForm } from 'react-hook-form'; 
import { FormControl, TextField, Button, FormControlLabel, Checkbox, Divider, MenuItem, Select, InputLabel, Grid } from '@mui/material';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const GetQuotePublic = () => {
    const userInfo = useSelector((state) => state.auth.user)

    const {register, handleSubmit, formState: { errors }, watch } =  useForm({
        defaultValues: {
            dealStatus: 'Just Looking',
            pools: false,
            fitnessRooms: false,
            sportCourts: false,
            playgrounds: false,
            coveredParking: false,
            fullName: userInfo ? userInfo.name : '',
            email: userInfo ? userInfo.email : '',
            callForOffersDate: null,
            renewalDate: null,
            tentativeClosingDate: null
    }})

    // const navigate = useNavigate()


    const onSubmit = async (data) => {
        // console.log(data)
        const resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_HOST}/api/quotes/create`, data)
        if (resp.status === 200 || resp.status === 201){
            window.confirm('Please check your mailbox for the quote!')
        }else{
            console.log(resp.data)
        }

    }
    const dealStatus = watch('dealStatus')


    const dealStatusOptions = [
        { value: 'Renewal', label: 'Renewal' },
        { value: 'Under Contract', label: 'Under Contract' },
        { value: 'Just Looking', label: 'Just Looking' },
    ]

    return (
        <>
            <div style={{margin: '3em', display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
                <h1 data-testid='get-quote-heading'>Get an instant Quote! </h1>
                <div style={{width: '80%', alignItems: 'center'}}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl style={{display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '100%', alignItems: 'left', marginTop: '1em'}}>
                            {/* ------ */}
                            <Divider flexItem style={{marginBottom: '1em'}} />
                            <Grid container spacing={8} style={{display: 'flex', flexDirection: 'row'}}>

                                {/* Property Details and Amenties */}
                                <Grid item xs={12} sm= {6} style={{display: 'flex', flexDirection: 'column'}}>
                                    <h5 style={{marginBottom: '1em'}} >Property Details</h5>

                                    <TextField 
                                    {...register('propertyName', {
                                        required: 'Property Name is required',
                                        pattern: {
                                            value: /^[A-Za-z0-9\s]{3,50}$/,
                                            message: 'Invalid Property Name'
                                        }
                                    })} 
                                    type='text' label='Property Name' variant='outlined' margin='normal' required />
                                    {errors.propertyName && <span style={{color: 'red'}}>{errors.propertyName.message}</span>}

                                    <TextField 
                                    {...register('propertyAddress', {
                                        required: 'Property Address is required',
                                        pattern: {
                                            value: /^[A-Za-z0-9\s]{3,50}$/,
                                            message: 'Invalid Property Address'
                                        }
                                    })} 
                                    type='text' label='Property Address' variant='outlined' margin='normal' required/>
                                    {errors.propertyAddress && <span style={{color: 'red'}}>{errors.propertyAddress.message}</span>}

                                    <TextField
                                    {...register('numOfUnits', {
                                        required: 'Number of Units is required',
                                        pattern: {
                                            value: /^[0-9]{1,5}$/,
                                            message: 'Invalid Number of Units'
                                        },
                                        min: {
                                            value: 100,
                                            message: 'Number of Units must be greater than 99'
                                        }
                                    })}
                                    type='number' label='Number of Units' variant='outlined' margin='normal' required placeholder='100+' />
                                    {errors.numOfUnits && <span style={{color: 'red'}}>{errors.numOfUnits.message}</span>}

                                    <TextField
                                    {...register('avgSqftPerUnit', {
                                        required: 'Average Sqft per Unit is required',
                                        pattern: {
                                            value: /^[0-9]{1,5}$/,
                                            message: 'Invalid Average Sqft per Unit'
                                        },
                                        min: {
                                            value: 400,
                                            message: 'Average Sqft per Unit must be greater than 400'
                                        },
                                        max: {
                                            value: 3000,
                                            message: 'Average Sqft per Unit must be less than 3000'
                                        }
                                    })}
                                    type='number' label='Average Sqft per Unit' variant='outlined' margin='normal' required />
                                    {errors.avgSqftPerUnit && <span style={{color: 'red'}}>{errors.avgSqftPerUnit.message}</span>}

                                    <TextField
                                    {...register('avgRentPerUnit', {
                                        required: 'Average Rent per Unit is required',
                                        pattern: {
                                            value: /^[0-9]{1,5}$/,
                                            message: 'Invalid Average Rent per Unit'
                                        },
                                        min: {
                                            value: 400,
                                            message: 'Average Sqft per Unit must be greater than 400'
                                        },
                                        max: {
                                            value: 3000,
                                            message: 'Average Sqft per Unit must be less than 3000'
                                        }
                                    })}
                                    type='number' label='Average Rent per Unit' variant='outlined' margin='normal' required />
                                    {errors.avgRentPerUnit && <span style={{color: 'red'}}>{errors.avgRentPerUnit.message}</span>}

                                    {/* ------ */}
                                    <Divider flexItem style={{marginBottom: '1em', marginTop: '1em'}} />

                                    <h5 style={{marginBottom: '2em'}} >Amenties</h5>
                                    <Grid container spacing={2} style={{marginLeft: '0.3em'}} >
                                        <FormControlLabel control={<Checkbox id='pools' inputProps={{ 'data-testid': 'get-quote-pools' }} />} label='Pools' {...register('pools')} />
                                        <FormControlLabel control={<Checkbox id='fitnessRooms' inputProps={{ 'data-testid': 'get-quote-fitnessRooms' }} />} label='Fitness Rooms' {...register('fitnessRooms')} />
                                        <FormControlLabel control={<Checkbox id='sportCourts' inputProps={{ 'data-testid': 'get-quote-sportCourts' }} />} label='Sport Courts' {...register('sportCourts')} />
                                        <FormControlLabel control={<Checkbox id='playgrounds' inputProps={{ 'data-testid': 'get-quote-playgrounds' }} />} label='Playgrounds' {...register('playgrounds')} />
                                        <FormControlLabel control={<Checkbox id='coveredParking' inputProps={{ 'data-testid': 'get-quote-coveredParking' }} />} label='Covered Parking'  {...register('coveredParking')} />
                                    </Grid>
                                </Grid>

                                {/* Contact Information and Deal Information  */}
                                <Grid item xs={12} sm={6} style={{display: 'flex', flexDirection: 'column'}}>     
                                    <h5 style={{marginBottom: '1em'}} >Contact Information</h5>

                                    <TextField
                                    {...register('fullName', {
                                        required: 'Full Name is required',
                                        pattern: {
                                            value: /^[A-Za-z\s]{3,50}$/,
                                            message: 'Invalid Full Name'
                                        }
                                    })}
                                    type='text' label='Full Name' placeholder='John Doe' variant='outlined' margin='normal' required disabled={!!userInfo}/>
                                    {errors.fullName && <span style={{color: 'red'}}>{errors.fullName.message}</span>}

                                    <TextField
                                    {...register('email', {
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            message: 'Invalid Email'
                                        }
                                    })}
                                    type='email' label='Email' placeholder='johndoe@test.com' variant='outlined' margin='normal' required disabled={!!userInfo}/>
                                    {errors.email && <span style={{color: 'red'}}>{errors.email.message}</span>}

                                    <TextField
                                    {...register('phone', {
                                        required: 'Phone Number is required',
                                        pattern: {
                                            value: /^[0-9]{10}$/,
                                            message: 'Invalid Phone Number'
                                        }
                                    })}
                                    type='tel' label='Phone Number' placeholder='(123) 456 7890' variant='outlined' margin='normal' required />
                                    {errors.phone && <span style={{color: 'red'}}>{errors.phone.message}</span>}

                                    {/* ------ */}
                                    <Divider flexItem style={{marginBottom: '1em', marginTop: '4.1em'}} />
                                    <h5 style={{marginBottom: '1em'}} >Deal Information</h5>
                                    <FormControl style={{ marginBottom: '1em', width: '100%'}} margin='normal'>
                                        <InputLabel id="demo-simple-select-label">Deal Status </InputLabel>
                                        <Select label="dealStatus" {...register('dealStatus')} InputLabelProps={{ shrink: false, }} data-testid='get-quote-dealStatus' value={dealStatus} >
                                            {dealStatusOptions.map((type) => (
                                                <MenuItem key={type.value} value={type.value} data-testid={'status-' + type.value}>
                                                    {type.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    { dealStatus === 'Renewal' && <TextField {...register('renewalDate')} type='date' label='Renewal Date?' variant='outlined' fullWidth margin='normal' required InputLabelProps={{ shrink: true }} />  }
                                    { dealStatus === 'Under Contract' && <TextField {...register('tentativeClosingDate')} type='date' label='Tentative Closing Date?' variant='outlined' fullWidth margin='normal' required InputLabelProps={{ shrink: true }} /> }
                                    { dealStatus === 'Just Looking' && <TextField {...register('callForOffersDate')} type='date' label='Call for Offers Date?' variant='outlined' fullWidth margin='normal' required InputLabelProps={{ shrink: true }} /> }
                                </Grid>
                            </Grid>

                            {/* ------ */}
                            <Divider flexItem style={{marginBottom: '2em', marginTop: '1em'}} />
                            <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                                <Grid item xs={12} sm={8} md={4} lg={3} style={{display: 'flex', justifyContent: 'center'}}>
                                    <Button variant='contained' color='primary' style={{width: '100%'}} type='submit'  data-testid='submit-quote-button'>Get Quote</Button>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </form>
                </div>
            </div>

        </>
    )
}

export default GetQuotePublic