import {React, useEffect, useState}  from 'react';
import { Button, Divider, FormControl, FormControlLabel, Checkbox, Grid, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, Input, Autocomplete} from '@mui/material';
import { useForm, Controller, set } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { convertToBase64 } from '../../Common.js'


const SubmitLiabilityQuote = () => { 

    const [loading, setLoading] = useState(false)
    const [carrierList, setCarrierList] = useState(null)
    const [selectedCarrier, setSelectedCarrier] = useState(null)   
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
    const [limitationsData, setLimitationsData] = useState([
        { name: 'Punitive Damages', perOccurence: 0, annualAggregate: 0, exclusion : false, hasLimitation: false },
        { name: 'Assault & Battery', perOccurence: 0, annualAggregate: 0, exclusion : false, hasLimitation: false },
        { name: 'Firearms', perOccurence: 0, annualAggregate: 0, exclusion : false, hasLimitation: false },
        { name: 'Animal Attacks', perOccurence: 0, annualAggregate: 0, exclusion : false, hasLimitation: false },
        { name: 'Sexual Abuse/Molestation', perOccurence: 0, annualAggregate: 0, exclusion : false, hasLimitation: false },
        { name: 'Murder & Rape', perOccurence: 0, annualAggregate: 0, exclusion : false, hasLimitation: false }
    ]
    )
    const { control } = useForm();
    const { id: glCoverageId } = useParams()
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(window.location.search)
    const propertyName = queryParams.get('propertyName')
    const llcName = queryParams.get('llcName')
    const liabilityType = queryParams.get('type')
  


    const {register, handleSubmit, formState: { errors }, watch, setValue } =  useForm({
        defaultValues: {
            covgId: glCoverageId,
            coverageType: 'GL-HNOA-TRIA w/ 2 AI-WOS-PNC',
            llcName: llcName,
            propertyName: propertyName,
            agencyFee: 0,
            carrier: null


    }})

    const handleConfirmationModalClose = () => { 
        setConfirmationModalOpen(false);
    }

    // const convertToBase64 = (file) => new Promise((resolve, reject) => {
    //     var reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => resolve(reader.result);
    //     reader.onerror = (error) => reject(error);
    // });

    const onSubmit = async (data) => {
        // console.log('in onsubmit')
        // console.log(data)
        const { llcName, propertyName, quoteFile, ...rest} = data;
        // const fileBase64 = await convertToBase64(quoteFile[0])
        const sanitizedFile = await convertToBase64(quoteFile[0])
        const combinedData = {
            ...rest,
            carrierId: selectedCarrier.id,
            limitations: limitationsData,
            quoteFileBlob : sanitizedFile
        }
        console.log(combinedData)
        let resp;
        if(liabilityType === 'gl'){
            resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_HOST}/api/quotes/gl/create`, combinedData)
            if(resp.status === 201 || resp.status === 200){
               setConfirmationModalOpen(true)
            }else{
                window.confirm('Failed to submit quote. Please try again later.')
            }
        }else if(liabilityType === 'umbrella'){
            resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_HOST}/api/quotes/umbrella/create`, combinedData)
        }

    }

    const getCarrierNames = async () => {
        setLoading(true)
        const resp = await axios.get(`${process.env.REACT_APP_BACKEND_API_HOST}/api/carriers/`)
        console.log(resp)
        if(resp.status !== 200) window.alert('An error occured, please try again later')
        const carriers = Object.entries(resp.data).map(([id, name]) => ({
            label: name,
            id: id,
          }));

        setCarrierList(carriers)
        setLoading(false)

    }

    useEffect(() => {
        getCarrierNames()
    }, [])



    return (

        loading ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </div>
        :
        <>
        <div style={{margin: '3em', display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
        <h1 data-testid='get-quote-heading'>Upload {liabilityType} Quote</h1>
        <Dialog open={confirmationModalOpen} onClose={handleConfirmationModalClose}>
            <DialogTitle>Quote Submitted Successfully! 🎉 </DialogTitle>
            <DialogActions>
                <Button onClick={() => {navigate(`/broker/coverages/gl/${glCoverageId}`)}}>OK</Button>
            </DialogActions>
        </Dialog>
        <div style={{width: '80%', alignItems: 'center'}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl style={{display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '100%', alignItems: 'left', marginTop: '1em'}}>
                    {/* ------ */}
                    <Divider flexItem style={{marginBottom: '1em'}} />
                    <Grid container spacing={8} style={{display: 'flex', flexDirection: 'row'}}>

                        {/* Quote Details */}
                        <Grid item xs={12} sm= {6} style={{display: 'flex', flexDirection: 'column'}}>
                            <h5 style={{marginBottom: '1em'}} >Quote Details</h5>

                            <Input
                                {...register('quoteFile', {
                                    required: 'Quote File is required',
                                    pattern: {
                                        value: /^[A-Za-z0-9\s]{3,50}$/,
                                        message: 'Invalid Quote File'
                                    }
                                })}
                                sx={{ marginTop: '1em', color: 'primary.main' }}
                                type="file"
                                inputProps={{ accept: 'application/pdf' }}
                                required
                            />

                            <TextField value={llcName} type='text' label='LLC Name' variant='outlined' margin='normal' disabled />
                            <TextField value={propertyName} ype='text' label='Property Name' variant='outlined' margin='normal' disabled />

                        
                            <Autocomplete
                                disablePortal
                                id='combo-box-carrier'
                                sx={{ marginTop: '1.5em', marginBottom: '1em'}}
                                options={ carrierList || []}
                                renderInput={(params) => <TextField {...params} label='Carrier' />}
                                value={selectedCarrier}
                                getOptionLabel = {(option) => option.label}
                                onChange={(_, newValue) => { setSelectedCarrier(newValue)}}
                            />


                            <TextField 
                            {...register('coverageType', {
                                required: 'Coverage Type is required',
                                pattern: {
                                    value: /^[A-Za-z0-9\s/-]{3,50}$/,
                                    message: 'Invalid Coverage Type'
                                }
                            })} 
                            type='text' label='Coverage Type' variant='outlined' margin='normal' required/>
                            {errors.coverageType && <span style={{color: 'red'}}>{errors.coverageType.message}</span>}

                            <TextField
                            {...register('appNumber', {
                                required: 'App Number is required',
                                pattern: {
                                    value: /^[A-Za-z0-9\s]{3,50}$/,
                                    message: 'Invalid App Number'
                                }
                            })}
                            type='text' label='App Number' variant='outlined' margin='normal' required />
                            {errors.appNumber && <span style={{color: 'red'}}>{errors.appNumber.message}</span>}

                            <TextField {...register('quoteExpiration')} type='date' label='Quote Expiration Date' variant='outlined' fullWidth margin='normal' required InputLabelProps={{ shrink: true }} /> 

                            <TextField
                            {...register('commissionPercentage', {
                                required: 'Commission Percentage is required',
                                pattern: {
                                    value: /^[0-9]+(\.[0-9]{1,2})?$/,
                                    message: 'Invalid Commission Percentage'
                                },
                                min: {
                                    value: 0,
                                    message: 'Percent should be greater than or equal to 0'
                                },
                                mimaxn: {
                                    value: 100,
                                    message: 'Percent should be less than or equal to 100'
                                }
                            })}
                            type='decimal' label='Commission Percentage' variant='outlined' margin='normal' required />
                            {errors.commissionPercentage && <span style={{color: 'red'}}>{errors.commissionPercentage.message}</span>}


                            <TextField
                            {...register('agencyFee', {
                                required: 'Agency Fee is required',
                                pattern: {
                                    value: /^[0-9]{1,5}$/,
                                    message: 'Invalid Agency Fee'
                                }
                            })}
                            type='number' label='Agency Fee' variant='outlined' margin='normal' required />
                            {errors.agencyFee && <span style={{color: 'red'}}>{errors.agencyFee.message}</span>}





                        </Grid>

                        <Grid item xs={12} sm= {6} style={{display: 'flex', flexDirection: 'column'}}>
                            <h5 style={{marginBottom: '1em'}} >Coverage Details</h5>

                            <TextField {...register('effectiveDate')} type='date' label='Effective Date' variant='outlined' fullWidth margin='normal' required InputLabelProps={{ shrink: true }} /> 
                            <TextField {...register('expirationDate')} type='date' label='Expiration Date' variant='outlined' fullWidth margin='normal' required InputLabelProps={{ shrink: true }} /> 
                            <TextField
                            {...register('deductible', {
                                required: 'Deductible is required',
                                pattern: {
                                    value: /^[0-9]{1,5}$/,
                                    message: 'Invalid Deductible'
                                }
                            })}
                            type='number' label='Deductible' variant='outlined' margin='normal' required />
                            {errors.deductible && <span style={{color: 'red'}}>{errors.deductible.message}</span>}

                            <TextField
                            {...register('pretaxPremium', {
                                required: 'Pre-Tax Premium is required',
                                pattern: {
                                    value: /^[0-9]{1,5}$/,
                                    message: 'Invalid Pre-Tax Premium'
                                }
                            })}
                            type='number' label='Pre-Tax Premium' variant='outlined' margin='normal' required />
                            {errors.pretaxPremium && <span style={{color: 'red'}}>{errors.pretaxPremium.message}</span>}

                            <TextField
                            {...register('annualPremium', {
                                required: 'Annual Premium is required',
                                pattern: {
                                    value: /^[0-9]{1,5}$/,
                                    message: 'Invalid Annual Premium'
                                }
                            })}
                            type='number' label='Annual Premium' variant='outlined' margin='normal' required />
                            {errors.annualPremium && <span style={{color: 'red'}}>{errors.annualPremium.message}</span>}

                            <Divider flexItem style={{marginBottom: '2em', marginTop: '1em'}} /> 
                            <h5 style={{marginBottom: '1em'}} >Limitations</h5>

                            {limitationsData.map((option, index) => {
                                return (
                                    <div key={index}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={option.hasLimitation}
                                            onChange={(event) => {
                                              let newOptions = [...limitationsData];
                                              newOptions[index].hasLimitation = event.target.checked;
                                              setLimitationsData(newOptions);
                                              console.log(limitationsData)
                                            }}
                                            name={option.name}
                                          />
                                        }
                                        label={option.name}
                                      />
                                          {option.hasLimitation && (
                                            <div>
                                                <TextField
                                                label="Per Occurrence"
                                                value={option.perOccurence}
                                                disabled={option.exclusion}
                                                sx={{marginRight: '1em'}}
                                                onChange={(event) => {
                                                    let newOptions = [...limitationsData];
                                                    newOptions[index].perOccurence = event.target.value;
                                                    setLimitationsData(newOptions);
                                                    console.log(limitationsData)
                                                }}
                                                />
                                                <TextField
                                                label="Per Year"
                                                value={option.annualAggregate}
                                                disabled={option.exclusion}
                                                sx={{marginRight: '1em'}}
                                                onChange={(event) => {
                                                    let newOptions = [...limitationsData];
                                                    newOptions[index].annualAggregate = event.target.value;
                                                    setLimitationsData(newOptions);
                                                    console.log(limitationsData)
                                                }}
                                                />
                                                <FormControlLabel
                                                control={
                                                    <Checkbox
                                                    checked={option.exclusion}
                                                    disabled={option.perOccurence != 0 || option.annualAggregate != 0}
                                                    onChange={(event) => {
                                                        let newOptions = [...limitationsData];
                                                        newOptions[index].exclusion = event.target.checked;
                                                        setLimitationsData(newOptions);
                                                        console.log(limitationsData)
                                                    }}
                                                    />
                                                }
                                                label="Exclusion"
                                                />
                                            </div>
                                            )}
                                    </div>
                                  );
                            })}
                            

                        </Grid>
                    </Grid>
                    <Divider flexItem style={{marginBottom: '2em', marginTop: '1em'}} />
                    <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                        <Grid item xs={12} sm={8} md={4} lg={3} style={{display: 'flex', justifyContent: 'center'}}>
                            <Button variant='contained' color='primary' style={{width: '100%'}} type='submit'  data-testid='submit-quote-button'>Submit Quote</Button>
                        </Grid>
                    </Grid>
                </FormControl>
            </form>
        </div>
    </div>
    </>
)}


export default SubmitLiabilityQuote

