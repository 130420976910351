import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios'

import { checkAuthenticated, load_user } from '../slices/authSlice';

const Layout = (props) => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    useEffect(() => {
        // console.log('isAuthenticated ', isAuthenticated)
        if (!isAuthenticated) {
            dispatch(checkAuthenticated());
            // console.log('after check authenticated, isAuthenticated ', isAuthenticated)
            dispatch(load_user());
            // console.log('after load user, isAuthenticated ', isAuthenticated)
        }
      }, [isAuthenticated, dispatch]);

    const accessToken = useSelector((state) => state.auth.access)

    axios.defaults.headers.common['Content-Type'] = 'application/json'
    axios.defaults.headers.common['Accept'] = 'application/json'
    if(accessToken) axios.defaults.headers.common['Authorization'] = `JWT ${accessToken}`
    // axios.interceptors.response.use(resp => {
    //     return resp;
    // }, error => {
    //     if (error.response.status === 401){
    //         dispatch(checkAuthenticated())
    //         dispatch(load_user())
    //     }
    //     return error;
    // })

    return (
        <>
            <Navbar />
            {props.children}
        </>
    )
}


export default Layout