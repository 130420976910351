import React from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import { Card, CardContent, Typography, TextField, Select, Button, InputLabel, MenuItem, FormControl} from '@mui/material';
import axios from 'axios';

const SubmitClaim = () => {
    const [lossTypeSelected, setLossTypeSelected] = React.useState('Property');
    const [dateOfLoss, setDateOfLoss] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [claimNum, setClaimNum] = React.useState('');
    const [lossAmount, setLossAmount] = React.useState(0);

    const location = useLocation();
    const navigate = useNavigate();
    const acctId = location.state.acctId;
    const acctName = location.state.acctName;

    const claimTypes = [
        { value: 'Property', label: 'Property' },
        { value: 'Liability', label: 'Liability' },
        { value: 'Flood', label: 'Flood' },
    ]

    const handleLossTypeChange = (event) => {
        setLossTypeSelected(event.target.value);
    }

    const handleDateOfLossChange = (event) => {
        setDateOfLoss(event.target.value);
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    }

    const handleClaimNumChange = (event) => {
        setClaimNum(event.target.value);
    }

    const handleLossAmountChange = (event) => {
        setLossAmount(event.target.value);
    }

    const handleSubmitClaim = async() => {
        try{
            const body = { 
                lossType: lossTypeSelected, 
                dateOfLoss: dateOfLoss, 
                description: description, 
                accountId: acctId,
                claimStage: 'Claim Opened', 
                claimNumber: claimNum,
                totalGrossLossAmount: lossAmount
            }

            const resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_HOST}/api/claims/create`, body)
            // console.log(resp.data)
            if (resp.status !== 201) {
                console.error('Failed to submit claim:', resp)
                window.confirm('Failed to submit claim. Please try again.')
                return
            }else{
                window.confirm('Claim submitted successfully')
                navigate('/claims/' + resp.data.claimId)
            }
            
        } catch (error){
            console.error('Failed to submit claim:', error)
        }
    }

    return (
        <>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginLeft: '11em', marginRight: '10em', marginTop: '3em', marginBottom: '1em'}}>
            <h1 style={{marginBottom: '0em'}} data-testid='submit-claim-heading'>Create Claim</h1>
        </div>
        <div style={{marginLeft: '10em', marginRight: '10em', marginTop: '0em'}}>
            <Card variant='outlined' style={{minHeight:'80vh', boxShadow: '5px 5px 20px #E9E9E9', borderRadius: '1em'}}>
                <CardContent >
                    <Typography variant='h6' style={{padding: '0.5em', marginBottom: '1em'}}>Basic Information</Typography>
                    <div style={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <FormControl style={{ marginBottom: '1em', width: '100%'}} >
                            <InputLabel id="demo-simple-select-label">Loss Type</InputLabel>
                            <Select
                                data-testid='submit-claim-loss-type'
                                label="Loss Type"
                                value={lossTypeSelected}
                                onChange={handleLossTypeChange}
                                style={{ marginBottom: '1em' }}
                
                            >
                                {claimTypes.map((type) => (
                                    <MenuItem key={type.value} value={type.value} data-testid={'loss-' + type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField label="Account Name" value={acctName} style={{width: '100%', marginBottom: '1em'}} InputLabelProps={{ shrink: true }} disabled inputProps={{ 'data-testid': 'submit-claim-acctName' }}/>
                        <TextField label="Claim Number" value={claimNum} onChange={handleClaimNumChange} inputProps={{ 'data-testid': 'submit-claim-claimNum' }}  style={{width: '100%', marginBottom: '1em'}} InputLabelProps={{ shrink: true }} />
                        <TextField label="Date of Loss" type='date' value={dateOfLoss} onChange={handleDateOfLossChange} inputProps={{ 'data-testid': 'submit-claim-dateOfLoss' }} style={{width: '100%', marginBottom: '1em'}} InputLabelProps={{ shrink: true }}/>   
                        <TextField label='Total Gross Loss Amount' type='number' value={lossAmount} onChange={handleLossAmountChange}  inputProps={{ 'data-testid': 'submit-claim-totalLossAmount' }} style={{width: '100%', marginBottom: '1em'}} InputLabelProps={{ shrink: true }}/>
                        <TextField label='Description' multiline rows={4} value={description} onChange={handleDescriptionChange} inputProps={{ 'data-testid': 'submit-claim-description' }} style={{width: '100%', marginTop: '1em'}} InputLabelProps={{ shrink: true }}/>
                        <Button variant='contained' color='primary' data-testid='submit-claim-submitButton' style={{width: '20%', marginTop: '5em'}} onClick={() => handleSubmitClaim()}>Submit Claim</Button>
                    </div>
                </CardContent>
            </Card>
        </div>
        </>
    )
}


export default SubmitClaim