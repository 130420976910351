import React from 'react';

const convertToBase64Helper = (file) => new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

const convertToBase64 = async (file) => {
    let base64 = await convertToBase64Helper(file);
    return base64.replace(/^data:application\/pdf;base64,/, "");
}

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

const formatDate = (date) => {
    const d = new Date(date);
    return d.toLocaleDateString();
}

const formatPercent = (percent) => {
    return percent.toFixed(2) + '%';
}

export { convertToBase64, USDollar, formatDate, formatPercent };