import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: null,
    isVerifying: null,
    user: null
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        AUTHENTICATED_SUCCESS: (state) => {
            // console.log('auth success!')
            state.isAuthenticated = true;
            state.isVerifying = false;
        },
        LOGIN_SUCCESS: (state, action) => {
            // console.log('login success!')
            localStorage.setItem('access', action.payload.access)
            localStorage.setItem('refresh', action.payload.refresh)

            state.isAuthenticated = true;
            state.isVerifying = false;
            state.access = action.payload.access;
            state.refresh = action.payload.refresh;
            state.user = action.payload;
        },
        USER_LOADED_SUCCESS: (state, action) => {
            console.log('user loaded!')
            state.user = action.payload;
        },
        USER_LOADED_FAIL: (state) => {
            console.log('user load failed')
            state.user = null;
        },
        AUTHENTICATED_FAIL: (state) => {
            console.log('auth failed')
            state.isAuthenticated = false;
            state.isVerifying = false;
        },
        LOGIN_FAIL: (state) => {
            // console.log('log in failed')
            localStorage.removeItem('access')
            localStorage.removeItem('refresh')

            state.isAuthenticated = false
            state.isVerifying = false;
            state.access = null
            state.refresh = null
            state.user = null
        },
        LOGOUT: (state) => {
            // console.log('logging out')
            localStorage.removeItem('access')
            localStorage.removeItem('refresh')

            state.isAuthenticated = false
            state.isVerifying = false;
            state.access = null
            state.refresh = null
            state.user = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkAuthenticated.pending, (state) => {
                state.isVerifying = true;
            })
            .addCase(checkAuthenticated.fulfilled, (state) => {
                state.isVerifying = false;
            })
            .addCase(checkAuthenticated.rejected, (state) => {
                state.isAuthenticated = false;
                state.isVerifying = false;
            })
    }
})

export const login = createAsyncThunk(
    'auth/login',
    async ({email, password}, thunkAPI) => {
        const config = {
            headers:{
                'Content-Type' : 'application/json'
            }
        }
    
        const body = JSON.stringify({email, password});
    
        try{
            const resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_HOST}/auth/jwt/create/`, body, config)
    
            thunkAPI.dispatch(LOGIN_SUCCESS(resp.data));
            thunkAPI.dispatch(load_user())
        } catch (error){
            thunkAPI.dispatch(LOGIN_FAIL())
        }
    }
)

export const load_user = createAsyncThunk(
    'auth/load_user',
    async (_, thunkAPI) => {
        if (localStorage.getItem('access')){
            const config = {
                headers:{
                    'Content-Type' : 'application/json',
                    'Authorization' : `JWT ${localStorage.getItem('access')}`,
                    'Accept' : 'application/json'
                }
            }
    
            try{
                const resp = await axios.get(`${process.env.REACT_APP_BACKEND_API_HOST}/auth/users/me/`, config)
                console.log('load user resp: ', resp.data)
                if(resp.data == null) {
                    thunkAPI.dispatch(USER_LOADED_FAIL())
                }else{
                    thunkAPI.dispatch(USER_LOADED_SUCCESS(resp.data))
                }
            } catch (error){
                thunkAPI.dispatch(USER_LOADED_FAIL())
            }
        } else {
            thunkAPI.dispatch(USER_LOADED_FAIL())
        }
    }
)

export const checkAuthenticated = createAsyncThunk(
    'auth/checkAuthenticated',
    async (_, thunkAPI) => {
        console.log('checking authenticated called. Access token: ' + localStorage.getItem('access'))
        if (localStorage.getItem('access')){
            const config = {
                headers:{
                    'Content-Type' : 'application/json',
                    'Accept' : 'application/json'
                }
            }
    
            const body = JSON.stringify({ token: localStorage.getItem('access') });
    
            try{
                const resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_HOST}/auth/jwt/verify/`, body, config)
                console.log('/auth/jwt/verify/ resp: ', resp.data)
                if (resp.data.code !== 'token_not_valid'){
                    console.log('authenticated success')
                    thunkAPI.dispatch(AUTHENTICATED_SUCCESS())
                } else {
                    console.log('authenticated fail')
                    thunkAPI.dispatch(AUTHENTICATED_FAIL())
                }
            } catch (error){
                console.log('authenticated fail with error ', error)
                thunkAPI.dispatch(AUTHENTICATED_FAIL())
            }
        } else {
            console.log('no access token so cannot authenticate')
            thunkAPI.dispatch(AUTHENTICATED_FAIL())
        }
    }
)

export const { 
    AUTHENTICATED_SUCCESS,
    USER_LOADED_SUCCESS,
    LOGIN_SUCCESS,
    AUTHENTICATED_FAIL,
    USER_LOADED_FAIL,
    LOGIN_FAIL,
    LOGOUT
} = authSlice.actions;

export default authSlice.reducer;