import { React, useState, useEffect}  from 'react';
import { useParams, useNavigate, useLocation} from 'react-router-dom';
import { Button, Card, CardContent, CircularProgress, TableContainer, Typography, Divider, Grid, Link, ListItem, ListItemText } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { formatDate, USDollar, formatPercent } from '../../Common';
import complex_placeholder from '../../images/complex_placeholder.png';

import axios from 'axios';
const BrokerAccountDetails = () => {
    
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    const [covgDetails, setCovgDetails] = useState(null);

    const getCovgDetails = async() => {
        setIsLoading(true)
        const resp = await axios.get(`${process.env.REACT_APP_BACKEND_API_HOST}/api/accounts/broker/${id}`)
        // console.log(resp)
        if(resp.status !== 200) navigate(`${location.pathname}/404`)
        setCovgDetails(resp.data)
        setIsLoading(false)

    };

    useEffect(() => {
        getCovgDetails()
    }, [id])


    return (
        isLoading ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </div>
        :
        <>
       
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', marginTop: '1em', marginLeft: '11em', marginRight: '10em', alignItems: 'left'}}>
            <div style={{width: '85%'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '2em'}}>
                    <h1 style={{marginTop: '1em' }}>Account Details</h1>
                </div>
                <Divider flexItem  />

                <div style={{ display: 'flex', flexDirection: 'row' }}> 
                    <div style={{ flex: '30%', padding: '1em' }}>
                        <img src={covgDetails.propertyImage == null ? complex_placeholder : covgDetails.propertyImage} alt={covgDetails.name} style={{ width: '100%', borderRadius: '1em' }} />
                    </div>
                    <div style={{ flex: '70%', padding: '1em', marginLeft: '3em' }}>
                        <Typography variant="h5" data-testid='acct-name' ><Link target="_blank" href={covgDetails.website}> {covgDetails.name} <OpenInNewIcon/> </Link></Typography>
                        <Typography variant="h6" color="text.secondary" style={{marginBottom: '1em'}}> {covgDetails.llcName}</Typography>
                        <Typography variant="body1"> Address: {covgDetails.address} </Typography>
                        <Typography variant="body1"> Building Breakdown: {covgDetails.bldgBreakdown} </Typography>
                    </div>
                </div>
                <Divider flexItem  />
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1em', padding: '1em'}}>
                <Grid container spacing={2} >
                    
                    <Grid item xs={2} style={{"align-items": "center", "display": "flex", "flex-direction": "column"}}>
                        <ListItem><ListItemText  primary={<Typography variant="body1" style={{ fontWeight: 'bold' }}>Characteristics</Typography>}/></ListItem>
                        <Divider flexItem />
                        <ListItem> <ListItemText primary={covgDetails.numUnits} secondary='# of Units'/></ListItem> 
                        <ListItem> <ListItemText primary={covgDetails.numBldgs} secondary='# of Buildings'/></ListItem> 
                        <ListItem> <ListItemText primary={covgDetails.county} secondary='County'/></ListItem>
                        <ListItem> <ListItemText primary={covgDetails.constructionType} secondary='Construction Type'/></ListItem>
                        <ListItem> <ListItemText primary={covgDetails.yearBuilt} secondary='Year Built'/></ListItem>
                    </Grid>

                    <Grid item xs={2} style={{"align-items": "center", "display": "flex", "flex-direction": "column"}}>
                        <ListItem><ListItemText  primary=''/></ListItem>
                        <ListItem><ListItemText  primary=''/></ListItem>
                        <Divider flexItem />
                        <ListItem> <ListItemText primary={USDollar.format(covgDetails.renovationCosts)} secondary='Renovation Costs'/></ListItem>
                        <ListItem> <ListItemText primary={covgDetails.numStories} secondary='# of Stories'/></ListItem>
                        <ListItem> <ListItemText primary={formatPercent(covgDetails.percentOccupied)} secondary='% Occupied'/></ListItem>
                        <ListItem> <ListItemText primary={Intl.NumberFormat('en-US').format(covgDetails.sqft)} secondary='Square Feet'/></ListItem>
                        {/* <ListItem> <ListItemText primary={covgDetails.numStories} secondary='Stories'/></ListItem> */}
                    </Grid>

                    <Grid item xs={2} style={{"align-items": "center", "display": "flex", "flex-direction": "column"}}>
                        <ListItem><ListItemText  primary={<Typography variant="body1" style={{ fontWeight: 'bold' }}>Amenities</Typography>}/></ListItem>
                        <Divider flexItem />
                        <ListItem > <ListItemText primary={covgDetails.numClubHouses} secondary='Club Houses'/></ListItem>
                        <ListItem > <ListItemText primary={covgDetails.numLeasingOffices} secondary='Leasing Offices'/></ListItem>
                        <ListItem > <ListItemText primary={covgDetails.numPools} secondary='Pools'/></ListItem>
                        <ListItem > <ListItemText primary={covgDetails.numPlaygrounds} secondary='Playgrounds'/></ListItem>
                        <ListItem > <ListItemText primary={covgDetails.numFitnessCenters} secondary='Fitness Centers'/></ListItem>
                        <ListItem > <ListItemText primary={covgDetails.numSportCourts} secondary='Sport Courts'/></ListItem>


                    </Grid>

                    <Grid item xs={2} style={{"align-items": "center", "display": "flex", "flex-direction": "column"}}>
                        <ListItem><ListItemText  primary={<Typography variant="body1" style={{ fontWeight: 'bold' }}>Utility Ages</Typography>}/></ListItem>
                        <Divider flexItem />
                        <ListItem > <ListItemText primary={covgDetails.roofAge} secondary='Roof Age'/></ListItem>
                        <ListItem > <ListItemText primary={covgDetails.hvacAge} secondary='HVAC Age'/></ListItem>
                        <ListItem > <ListItemText primary={covgDetails.electricalAge} secondary='Electrical Age'/></ListItem>
                        <ListItem > <ListItemText primary={covgDetails.plumbingAge} secondary='Plumbing Age'/></ListItem>

                    </Grid>

                    <Grid item xs={4} style={{"align-items": "center", "display": "flex", "flex-direction": "column"}}>
                        <ListItem><ListItemText  primary={<Typography variant="body1" style={{ fontWeight: 'bold' }}>Notes</Typography>}/></ListItem>
                        <Divider flexItem />
                        <ListItem > <ListItemText primary={covgDetails.roofAgeNotes} secondary='Roof Age Notes'/></ListItem>
                        <ListItem > <ListItemText primary={covgDetails.hvacAgeNotes} secondary='HVAC Age Notes'/></ListItem>
                        <ListItem > <ListItemText primary={covgDetails.electricalAgeNotes} secondary='Electrical Age Notes'/></ListItem>
                        <ListItem> <ListItemText primary={covgDetails.plumbingAgeNotes} secondary='Plumbing Age Notes'/></ListItem>

                    </Grid>


                </Grid>
                </div>
            </div>
        </div>
    
    </>
)}


export default BrokerAccountDetails