import React, { useEffect }  from 'react';

import { CircularProgress } from '@mui/material'

import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotFound from '../pages/NotFound';

const ProtectedRoute = (props) => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
    const isVerifying = useSelector((state) => state.auth.isVerifying)
    const isClient = useSelector((state) => state.auth.user?.user_groups?.includes('client'))
    const isBroker = useSelector((state) => state.auth.user?.user_groups?.includes('broker'))
    const allowed_group = props.allowed_group

    // console.log('isClient:', isClient + ' isBroker:', isBroker)

    // useEffect(() => {
    //     console.log(isAuthenticated)
    //     console.log(isVerifying)
    //     if ((isAuthenticated === false || isAuthenticated == null) && isVerifying === false){
    //         return <Navigate to='/login' />
    //     }
    // }, [isAuthenticated, isVerifying])

    if (isVerifying === null) {
        return <CircularProgress />; // Show a loading spinner while verifying
    }

    if ((isAuthenticated === false || isAuthenticated == null) && (isVerifying === false || isVerifying == null)){
        return <Navigate to='/login' />
    }

    if( (allowed_group === 'client' && !isClient) || (allowed_group === 'broker' && !isBroker)) {
        return <NotFound />
    }

    if (isVerifying){
        return (
            <div style={{width: "100%", minHeight: "10em", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <CircularProgress />
            </div>
        )
    } else {
        return props.children;
    }
}

export default ProtectedRoute