import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../Common';
export default function BrokerCoveragesTable(props) {

    const navigate = useNavigate();

    const rowClick = (id, type) => {
        navigate(`/broker/coverages/${type}/${id}`);
    };

    const accountClick = (id) => {
        navigate(`/broker/accounts/${id}`);
    }
    

    return (
        <>
            <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: '5em', paddingRight: '5em'}}>
                <h4 style={{marginTop: '1.5em', width: '75%', marginBottom: '1em'}} data-testid='quotes-heading'>{props.header} ({props.covgsList.length})</h4>
            </div>
            <div style={{paddingLeft: '5em', paddingRight: '5em', justifyContent: 'center', display:'flex'}}>
                <TableContainer component={Paper} style={{boxShadow: '5px 5px 20px #E9E9E9', borderRadius: '0.5em', width: '75%'}} >
                    <Table sx={{ minWidth: 650}} aria-label="simple table" >
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#E9E9E9'}}>
                                <TableCell style={{width: '10%', whiteSpace: 'nowrap'}}>LLC Name</TableCell>
                                <TableCell style={{width: '10%', whiteSpace: 'nowrap'}}>Property Name</TableCell>
                                <TableCell style={{width: '7%', whiteSpace: 'nowrap'}}>Effective Date</TableCell>
                                <TableCell style={{width: '7%', whiteSpace: 'nowrap'}}>Expiration Date</TableCell>
                                <TableCell style={{width: '2%', whiteSpace: 'nowrap'}}> Type</TableCell> 
                                <TableCell style={{width: '7%', whiteSpace: 'nowrap'}}>Stage</TableCell>
                                <TableCell style={{width: '1%', whiteSpace: 'nowrap'}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.covgsList.map((row) => (
                                <TableRow  key={row.id} style={row.numQuotes === 0 ? { borderColor: 'orange', borderWidth: '0.2em'} : {}}>
                                    <TableCell> {row.llcName}</TableCell>
                                    <TableCell onClick={() => accountClick(row.acctId)} style={{color: 'orange'}} hover>{row.propertyName}</TableCell>
                                    <TableCell>{formatDate(row.effectiveDate)}</TableCell>
                                    <TableCell>{formatDate(row.expirationDate)}</TableCell>
                                    <TableCell>{row.type}</TableCell>
                                    <TableCell>{row.stage}</TableCell>
                                    <TableCell onClick={() => rowClick(row.id , row.type.toLowerCase())} >→</TableCell>
                                </TableRow> 
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}