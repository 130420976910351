import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';

import complex_placeholder from '../images/complex_placeholder.png';

export default function ApartmentCard(props) {
  return (
    <Card sx={{ maxWidth: 400 }}>
      <CardActionArea component={Link} to={`/accounts/${props.account.id}`}>

        <CardMedia
          component="img"
          height="200"
          image={props.account.propertyImage != null ? props.account.propertyImage : complex_placeholder}
        />

        <CardContent>
          <Typography gutterBottom variant="h5" component="div" data-testid="acctName">
            {props.account.name}
          </Typography>

          <Typography gutterBottom variant="caption" color="text.secondary" component="div">
            {props.account.llcName}
          </Typography>

          <Typography gutterBottom variant="caption" color="text.secondary" component="div">
            {props.account.address}
          </Typography>

        </CardContent>
      </CardActionArea>
    </Card>
  );
}