import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, TableSortLabel } from '@mui/material';
import axios from 'axios';


const Quotes = () => {
    const navigate = useNavigate();

    const [quotesList, setQuotesList] = useState([])
    const [order, setOrder] = useState('asc')
    const [isLoading, setIsLoading] = useState(true)

    const getAllQuotes = async() => {
        try{
            setIsLoading(true)
            const resp = await axios.get(`${process.env.REACT_APP_BACKEND_API_HOST}/api/estimates/`)
            setQuotesList(resp.data)
            setIsLoading(false)
        } catch (error){
            setQuotesList([])
            console.error('Failed to get all quotes:', error)
        }
    }

    useEffect(() => {
        getAllQuotes()
    }, [])

    const sorting = (property) => {
        if (order === 'asc') {
            setQuotesList([...quotesList].sort((a, b) => a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1))
            setOrder('desc')
        } else {
            setQuotesList([...quotesList].sort((a, b) => a[property].toLowerCase() < b[property].toLowerCase() ? 1 : -1))
            setOrder('asc')
        }
    }


    return (
        isLoading ? 
            <div style={{width: "100%", minHeight: "10em", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <CircularProgress />
            </div>
            :
            <>
            <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginLeft: '5em', marginRight: '5em' }}>
                <div></div>
                <h1 style={{marginTop: '1em', fontSize: '2em', marginBottom: '1em'}} data-testid='quotes-heading'>Quotes</h1>
                <Button variant='contained' onClick={() => {navigate('/get-quote')}}>Get an Instant Quote!</Button>
            </div>
            <div style={{paddingLeft: '5em', paddingRight: '5em'}}>
                <TableContainer component={Paper} style={{boxShadow: '5px 5px 20px #E9E9E9', borderRadius: '0.5em'}} >
                    <Table sx={{ minWidth: 650}} aria-label="simple table" >
                        <TableHead>
                        <TableRow sx={{ backgroundColor: '#E9E9E9'}}>
                            <TableCell>Property Name</TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={true}
                                    direction={order}
                                    onClick={() => sorting('estimateDate')}
                                >
                                    Estimate Date
                                </TableSortLabel></TableCell>
                            <TableCell align="right">Total Estimated Premium</TableCell>
                            <TableCell align="right">Total Price Per Door</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {quotesList.map((row) => (
                            <TableRow key={row.id} hover>
                                <TableCell> {row.propertyName}</TableCell>
                                <TableCell align="right">{row.estimateDate}</TableCell>
                                <TableCell align="right">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.totalEstimate)}</TableCell>
                                <TableCell align="right">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.totalPricePerDoor)}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}

export default Quotes