import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, TableSortLabel, Chip, Divider } from '@mui/material';
import axios from 'axios';
import BrokerCoveragesTable from '../../components/BrokerCoveragesTable';
import {formatDate} from '../../Common';

const Coverages = () => {

    const [covgsList, setCovgsList] = useState([])
    const [order, setOrder] = useState('asc')
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate();

    const getAllExpiringGLCoverages = async() => {
        try{
            // console.log('here')
            setIsLoading(true)
            const resp = await axios.get(`${process.env.REACT_APP_BACKEND_API_HOST}/api/coverages/broker`)
            // console.log('resp:', resp.data)
            setCovgsList(resp.data)
            // setCovgsList([...covgsList].sort((a, b) => a['expirationDate'].toLowerCase() > b['expirationDate'].toLowerCase() ? 1 : -1))
            setIsLoading(false)
        } catch (error){
            setCovgsList([])
            console.error('Failed to get all quotes:', error)
        }
    }

    useEffect(() => {
        getAllExpiringGLCoverages()
    }, [])

    const rowClick = (id) => {
        navigate(`/broker/accounts/${id}`);
    };
    

    const sorting = (property) => {
        if (order === 'asc') {
            setCovgsList([...covgsList].sort((a, b) => a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1))
            setOrder('desc')
        } else {
            setCovgsList([...covgsList].sort((a, b) => a[property].toLowerCase() < b[property].toLowerCase() ? 1 : -1))
            setOrder('asc')
        }
    }


    return (
        isLoading ? 
        <div style={{width: "100%", minHeight: "10em", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <CircularProgress />
        </div>
        :
        <>
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <h1 style={{marginTop: '1em',  alignItems: 'center'}} data-testid='quotes-heading'>Coverages</h1>
        </div>
        <BrokerCoveragesTable header='Action Required Now' covgsList={covgsList.current} />
        <BrokerCoveragesTable header='Upcoming' covgsList={covgsList.upcoming} />
        <BrokerCoveragesTable header='Completed' covgsList={covgsList.completed} />
        </>

    )
}


export default Coverages