import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, CircularProgress } from '@mui/material';
import axios from 'axios';


const Claims = () => {
    const navigate = useNavigate();

    const [claimsList, setClaimsList] = useState([])
    const [order, setOrder] = useState('asc')
    const [isLoading, setIsLoading] = useState(true)

    const getAllClaims = async() => {
        try{
            setIsLoading(true)
            const resp = await axios.get(`${process.env.REACT_APP_BACKEND_API_HOST}/api/claims/`)
            setClaimsList(resp.data)
            setIsLoading(false)
        } catch (error){
            setClaimsList([])
            console.error('Failed to get all claims:', error)
        }
    }

    useEffect(() => {
        getAllClaims()
    }, [])


    const rowClick = (id) => {
        navigate(`/claims/${id}`);
    };
    
    const sorting = (property) => {
        if (order === 'asc') {
            setClaimsList([...claimsList].sort((a, b) => a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1))
            setOrder('desc')
        } else {
            setClaimsList([...claimsList].sort((a, b) => a[property].toLowerCase() < b[property].toLowerCase() ? 1 : -1))
            setOrder('asc')
        }
    }

    return (
        isLoading ? 
            <div style={{width: "100%", minHeight: "10em", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <CircularProgress />
            </div>
            :
            <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '5em', marginRight: '5em' }}>
                <h1 style={{marginTop: '1em', fontSize: '2em', marginBottom: '1em'}} data-testid='claims-heading'>Claims</h1>
            </div>
            <div style={{paddingLeft: '5em', paddingRight: '5em'}}>
                <TableContainer component={Paper} style={{boxShadow: '5px 5px 20px #E9E9E9', borderRadius: '0.5em'}} >
                    <Table sx={{ minWidth: 650}} aria-label="simple table" >
                        <TableHead>
                        <TableRow sx={{ backgroundColor: '#E9E9E9'}}>
                            <TableCell>Account Name</TableCell>
                            <TableCell align="right">Claim #</TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={true}
                                    direction={order}
                                    onClick={() => sorting('dateOfLoss')}
                                >
                                    Date of Loss
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">Loss Type </TableCell>
                            <TableCell align="right">Claim Stage</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {claimsList.map((row) => (
                            <TableRow key={row.id} onClick={() => rowClick(row.id)} hover>
                                <TableCell data-testid={'claims-acctName-' + row.id}><Link underline='hover' href={`/accounts/${row.accountId}` } onClick={(event) => event.stopPropagation()} >{row.accountName}</Link></TableCell>
                                <TableCell data-testid={'claims-claimNum-' + row.id } align="right">{row.id}</TableCell>
                                <TableCell data-testid={'claims-dateOfLoss-' + row.id} align="right">{row.dateOfLoss}</TableCell>
                                <TableCell data-testid={'claims-lossType-' + row.id} align="right">{row.lossType}</TableCell>
                                <TableCell data-testid={'claims-claimStage-' + row.id} align="right">{row.claimStage}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}

export default Claims