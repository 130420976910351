import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, Dialog, DialogActions, DialogContent, DialogTitle, TableContainer, TableHead, TableRow, Input, 
    CircularProgress, Typography, CardContent, Card, CardActions, Button, Divider, Paper, List, ListItem, ListItemText, Grid} from '@mui/material';
import complex_placeholder from '../../images/complex_placeholder.png';
import { convertToBase64, USDollar, formatDate, formatPercent} from '../../Common';
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
const CoverageDetails = () => {
    
    const [isLoading, setIsLoading] = useState(true)

    const [covgDetails, setCovgDetails] = useState({})

    const [uploadPolicyOpen, setUploadPolicyOpen] = useState(false)
    const [uploadCarrierInvoiceOpen, setUploadCarrierInvoiceOpen] = useState(false)
    const [submitPolicyNumberOpen, setSubmitPolicyNumberOpen] = useState(false)

    const [policyFile, setPolicyFile] = useState(null)
    const [carrierInvoiceFile, setCarrierInvoiceFile] = useState(null)
    const [policyNumber, setPolicyNumber] = useState('')

    const navigate = useNavigate()
    const { id: covgId } = useParams()

    const handleUploadPolicyClose = () => {
        setUploadPolicyOpen(false)
    }

    const handleUploadPolicyOpen = () => {
        setUploadPolicyOpen(true)
    }
    
    const handleUploadPolicyConfirm = async () => {
        console.log('uploading policy')
        const base64 = await convertToBase64(policyFile)
        const combinedData = {
            covgId: covgId,
            fileBlob: base64
        }
        // if(liabilityType === 'gl'){
        const resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_HOST}/api/coverages/broker/gl/uploadPolicy`, combinedData)
        // }else if(liabilityType === 'umbrella'){
            // resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_HOST}/api/coverages/umbrella/uploadPolicy`, combinedData)
        // }
    }

    const handlePolicyFileChange = (e) => {
        setPolicyFile(e.target.files[0])
    }

    const handleUploadPolicyCanceled = () => {
        setPolicyFile(null)
        setUploadPolicyOpen(false)
    }

    const handleUploadCarrierInvoiceClose = () => {
        setUploadCarrierInvoiceOpen(false)
    }

    const handleUploadCarrierInvoiceOpen = () => {
        setUploadCarrierInvoiceOpen(true)
    }

    const handleCarrierInvoiceFileChange = (e) => {
        setCarrierInvoiceFile(e.target.files[0])
    }

    const handleUploadCarrierInvoiceConfirm = async () => {
        console.log('uploading carrier invoice')
        const base64 = await convertToBase64(carrierInvoiceFile)
        const combinedData = {
            covgId: covgId,
            fileBlob: base64
        }
        // if(liabilityType === 'gl'){
        const resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_HOST}/api/coverages/broker/gl/uploadCarrierInvoice`, combinedData)
        // }else if(liabilityType === 'umbrella'){
            // resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_HOST}/api/coverages/umbrella/uploadCarrierInvoice`, combinedData)
        // }
    }

    const handleUploadCarrierInvoiceCanceled = () => {
        setCarrierInvoiceFile(null)
        setUploadCarrierInvoiceOpen(false)
    }

    const handleSubmitPolicyNumberClose = () => {
        setSubmitPolicyNumberOpen(false)
    }

    const handleSubmitPolicyNumberOpen = () => {
        setSubmitPolicyNumberOpen(true)
    }

    const handleSubmitPolicyNumberCanceled = () => {
        setPolicyNumber('')
        setSubmitPolicyNumberOpen(false)
    }

    const handleSubmitPolicyNumberConfirm = async () => {
        console.log('submitting policy number')
        const combinedData = {
            covgId: covgId,
            policyNumber: policyNumber
        }
        // if(liabilityType === 'gl'){
        const resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_HOST}/api/coverages/broker/gl/submitPolicyNumber`, combinedData)
        // }else if(liabilityType === 'umbrella'){
            // resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_HOST}/api/coverages/umbrella/submitPolicyNumber`, combinedData)
        // }
    }

    const goToSubmitQuote = () => {

        const params = new URLSearchParams({
            propertyName: covgDetails.accountDetails.name,
            llcName: covgDetails.accountDetails.llcName,
            type: covgDetails.currentCoverageDetails.type,
          });
        navigate(`/submit-liability-quote/${covgDetails.currentCoverageDetails.type}/${covgDetails.currentCoverageDetails.id}?${params.toString()}`)
    }


    useEffect(() => {
        console.log('in use effect')
        setIsLoading(true)
        axios.get(`${process.env.REACT_APP_BACKEND_API_HOST}/api/coverages/broker/gl/${covgId}`)
            .then(response => {
                console.log(response.data)
                setCovgDetails(response.data)
                setIsLoading(false)
            })
            .catch(error => {
                console.error(error)
                setIsLoading(false)
            })
    }, [covgId])

    return (
        isLoading ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </div> : 
        <>
            <div style={{display:'flex', alignItems: 'center', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '75%', alignItems: 'center', marginBottom: '2em'}}>
                    <h1 style={{marginTop: '1em' }}>Coverage Details</h1>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        { covgDetails.currentCoverageDetails.stage === 'Bound' ? 
                        <>
                            <Button variant='contained' style={{marginTop: '1em', marginLeft: '1em'}} onClick={() => {handleUploadPolicyOpen()}} disabled={covgDetails.currentCoverageDetails.isPolicyUploaded}>Upload Policy</Button>
                            <Dialog open={uploadPolicyOpen} onClose={handleUploadPolicyClose}>
                                <DialogTitle>Upload Policy</DialogTitle>
                                <DialogContent>
                                    <Input type="file" onChange={handlePolicyFileChange} />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleUploadPolicyCanceled}>Cancel</Button>
                                    <Button onClick={handleUploadPolicyConfirm}>Upload</Button>
                                </DialogActions>
                            </Dialog>
                            <Button variant='contained' style={{marginTop: '1em', marginLeft: '1em'}} onClick={() => {handleUploadCarrierInvoiceOpen()}} disabled={covgDetails.currentCoverageDetails.isCarrierInvoiceUploaded}>Upload Carrier Invoice</Button>
                            <Dialog open={uploadCarrierInvoiceOpen} onClose={handleUploadCarrierInvoiceClose}>
                                <DialogTitle>Upload Carrier Invoice</DialogTitle>
                                <DialogContent>
                                    <Input type="file" onChange={handleCarrierInvoiceFileChange} />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleUploadCarrierInvoiceCanceled}>Cancel</Button>
                                    <Button onClick={handleUploadCarrierInvoiceConfirm}>Upload</Button>
                                </DialogActions>
                            </Dialog>
                            <Button variant='contained' style={{marginTop: '1em', marginLeft: '1em'}} onClick={() => {handleSubmitPolicyNumberOpen()}} disabled={covgDetails.currentCoverageDetails.isPolicyNumberUploaded}>Submit Policy Number</Button>
                            <Dialog open={submitPolicyNumberOpen} onClose={handleSubmitPolicyNumberClose}>
                                <DialogTitle>Submit Policy Number</DialogTitle>
                                <DialogContent>
                                    <Input type="text" value={policyNumber} onChange={(e) => {setPolicyNumber(e.target.value)}} />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleSubmitPolicyNumberCanceled}>Cancel</Button>
                                    <Button onClick={handleSubmitPolicyNumberConfirm}>Submit</Button>
                                </DialogActions>
                            </Dialog>
                            </> : 
                            <>
                            <Button variant='contained' style={{marginTop: '1em', marginLeft: '1em'}} onClick={goToSubmitQuote}>Submit Quote</Button>
                            </>}
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', width: '75%', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flex: 1, marginRight: '2em'}}>
                        <Card variant="outlined" style={{boxShadow: '5px 5px 20px #E9E9E9', borderRadius: '1em', marginTop: '1em'}}>
                            <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* <div style={{ padding: '1em', marginLeft: '3em' }}> */}
                                    <img src={ complex_placeholder} alt='' style={{ width: '100%', height: '100%', borderRadius: '1em' }} />
                                    <Typography variant="h4" style={{marginBottom: '1em'}} data-testid='acct-name'>{covgDetails.accountDetails.name}</Typography>
                                    <Typography variant="body1" data-testid='acct-llc'>{covgDetails.accountDetails.llcName}</Typography>
                                    <Typography variant="body1" data-testid='acct-addr'>{covgDetails.accountDetails.address}</Typography>
                                    
                                {/* </div> */}
                            </CardContent>
                            <CardActions>
                                <Button size="large" onClick={() => {navigate(`/broker/accounts/${covgDetails.accountDetails.id}`)}}>See Account Details </Button>
                            </CardActions>
                        </Card>
                    </div>
                    <div style={{flex: 3}}>
                        <h5 style={{marginBottom: '1em'}}>Submitted Quotes</h5>
                        <TableContainer component={Paper} style={{boxShadow: '5px 5px 20px #E9E9E9', borderRadius: '0.5em'}}>
                            <Table sx={{ minWidth: 650}} aria-label="simple table" >
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#E9E9E9'}}>
                                        <TableCell style={{width: '20%', whiteSpace: 'nowrap'}}>Carrier</TableCell>
                                        <TableCell style={{width: '5%', whiteSpace: 'nowrap'}}>Quote Expiration</TableCell>
                                        <TableCell style={{width: '5%', whiteSpace: 'nowrap'}}>App #</TableCell>
                                        <TableCell style={{width: '7%', whiteSpace: 'nowrap'}}>Annual Premium</TableCell>
                                        <TableCell style={{width: '7%', whiteSpace: 'nowrap'}}>Deductible</TableCell>
                                        {/* <TableCell style={{width: '15%', whiteSpace: 'nowrap'}}>Limtiations</TableCell> */}
                                        <TableCell style={{width: '2%', whiteSpace: 'nowrap'}}>Accepted?</TableCell> 
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {covgDetails.quotesList.map((row) => (
                                        <TableRow key={row.appNum}>
                                            <TableCell> {row.carrier}</TableCell>
                                            <TableCell>{formatDate(row.quoteExpiration)}</TableCell>
                                            <TableCell>{row.appNum}</TableCell>
                                            <TableCell>{USDollar.format(row.premium)}</TableCell>
                                            <TableCell>{USDollar.format(row.deductible)}</TableCell>
                                            {/* <TableCell>{row.limitations}</TableCell> */}
                                            <TableCell>{row.accepted ? 'Yes' : 'No'}</TableCell>
                                        </TableRow> 
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                <Divider flexItem style={{marginTop: '2em', marginBottom: '2em', width: '75%', alignSelf: 'center'}}/>
                <div style={{display:'flex', justifyContent: 'left', flexDirection: 'column', width: '75%'}}>
                    <h5>Previous Coverage Details</h5>
                    { covgDetails.pastCoverageDetails ? 
                     <Grid container spacing={10} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                        <Grid item xs={4}>
                            <ListItem> <ListItemText primary={formatDate(covgDetails.pastCoverageDetails.effectiveDate)} secondary='Effective Date'/> </ListItem>
                            <ListItem> <ListItemText primary={formatDate(covgDetails.pastCoverageDetails.expirationDate)} secondary="Expiration Date"/> </ListItem>
                            <ListItem> <ListItemText primary={covgDetails.pastCoverageDetails.policyNumber} secondary="Policy Number"/> </ListItem>
                        </Grid>
                        <Grid item xs={4}>
                            <ListItem> <ListItemText primary={USDollar.format(covgDetails.pastCoverageDetails.preTaxPremium)} secondary="Pre-Tax Premium"/> </ListItem>
                            <ListItem> <ListItemText primary={USDollar.format(covgDetails.pastCoverageDetails.annualPremium)} secondary="Annual Premium"/> </ListItem>
                            <ListItem> <ListItemText primary={formatPercent(covgDetails.pastCoverageDetails.commissionPercentage)} secondary="Commission Percentage"/> </ListItem>
                        </Grid>
                        <Grid item xs={4}>
                            <ListItem> <ListItemText primary={USDollar.format(covgDetails.pastCoverageDetails.commission)} secondary="Commission $"/> </ListItem>
                            <ListItem> <ListItemText primary={USDollar.format(covgDetails.pastCoverageDetails.deductible)} secondary="Deductible"/> </ListItem>
                            <ListItem> <ListItemText primary={covgDetails.pastCoverageDetails.carrier} secondary="Carrier"/> </ListItem>
                        </Grid>
                    </Grid> : 
                    <>
                        <Typography>No previous coverage details available</Typography>
                    </>
                    }
                    
                </div>
            </div>
        </>)
}


export default CoverageDetails