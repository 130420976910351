// import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import {
  LOGOUT
} from '../slices/authSlice'
// import { connect } from 'react-redux'
// import { logout } from '../actions/auth'

import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import AdbIcon from '@mui/icons-material/Adb';

const clientPages = ['Accounts', 'Quotes', 'Claims'];
const brokerPages = ['Coverages'];
const unauthenticatedPages = ['Login'];

const settings = ['Profile', 'Logout'];

const Navbar = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const isClient = useSelector((state) => state.auth.user?.user_groups?.includes('client'))
  const isBroker = useSelector((state) => state.auth.user?.user_groups?.includes('broker'))
  const startUrl = (isClient ? '/accounts' : isBroker ? '/expiring-gl-coverages' : '/login')

  let navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleUserMenuClicked = (prop) => {
    let btnName = prop.target.textContent.toLowerCase()

    switch(btnName){
        case 'logout': 
            dispatch(LOGOUT())
            break
        default:
            console.error('unknown command')
    }

    setAnchorElUser(null);
  };

  const handleMenuItemClicked = (prop) => {
    let btnName = prop.target.value.toLowerCase().replaceAll(' ','-');
    

    switch(btnName){
        default:
          navigate(btnName)
    }
  }

  useEffect(() => {
    // console.log('navbar rendered')
  }, [])

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
            
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}

          <Typography
            variant="h6"
            noWrap
            component="a"
            href={startUrl}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            SIG
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {
                (isAuthenticated && isClient ? clientPages : isAuthenticated && isBroker? brokerPages : unauthenticatedPages).map((page) => (
                    <Button key={page} onClick={handleMenuItemClicked} sx={{ my: 2, color: 'white', display: 'block' }} value={page} data-testid={page}>
                        {page}
                    </Button>
                ))
            }
          </Box>


          {
            isAuthenticated ? 
            <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Test" src="/static/images/avatar/2.jpg" />
                </IconButton>
                </Tooltip>

                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleUserMenuClicked}
                >
                {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleUserMenuClicked} id={setting}>
                    <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                ))}
                </Menu>
            </Box>
            :
            null
          }

        </Toolbar>
      </Container>
    </AppBar>
  );
}
// export default ResponsiveAppBar;

// const Navbar = ({ logout, isAuthenticated}) => {
//     const guestLinks = () => (
//         <Fragment>
//             <li className='nav-item'>
//                 <Link className='nav-link' to='/login'>Log In</Link>
//             </li>
//         </Fragment>
//     )

//     const authLinks = () => (
//         <Fragment>
//             <li className='nav-item'>
//                 <Link className='nav-link' to='/accounts'>Accounts</Link>
//             </li>

//             <li className='nav-item'>
//                 <a className='nav-link' href='/login' onClick={ logout }>Log Out</a>
//             </li>
//         </Fragment>
//     )

//     return (
//         <nav className="navbar navbar-expand-lg bg-body-tertiary">
//             <div className="container-fluid">
//                 <Link className="navbar-brand" href="/">Navbar</Link>
//                 <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//                 <span className="navbar-toggler-icon"></span>
//                 </button>
//                 <div className="collapse navbar-collapse" id="navbarSupportedContent">
//                 <ul className="navbar-nav me-auto mb-2 mb-lg-0">
//                     {/* <li className='nav-item active'>
//                         <Link className='nav-link' to='/'>Home</Link>
//                     </li> */}
//                     {isAuthenticated ? authLinks() : guestLinks()}
//                 </ul>
//                 </div>
//             </div>
//         </nav>
//     )
// }

export default Navbar

// const mapStateToProps = state => ({
//     isAuthenticated: state.auth.isAuthenticated
// })

// export default connect(mapStateToProps, { logout })(Navbar)